import { Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss'],
})
export class ProposalsComponent implements OnInit {
  proposals;
  votingDuration;
  now;
  submitProposalRequiredPower;
  canVote;
  userPower;
  canSubmitByPower = false;
  canSubmitByDelay = false;
  activeProposals;
  endedProposals;

  actionTo;
  actionValue;
  actionData;
  details;
  offChainDetails;

  constructor(private readonly alohaService: AlohaService) {}

  async ngOnInit(): Promise<void> {
    this.now = Math.floor(Date.now());

    await this.alohaService.configNetwork();

    this.proposals = await this.alohaService.getProposals();
    this.votingDuration = await this.alohaService.getVotingDuration();
    this.activeProposals = this.proposals.filter(
      (e) => e.review === '1' && e.starting + this.votingDuration > this.now
    );
    this.endedProposals = this.proposals.filter(
      (e) => e.review === '1' && e.starting + this.now > this.votingDuration
    );

    if (this.alohaService.address) {
      this.alohaService.loading = true;
      this.submitProposalRequiredPower = await this.alohaService.getSubmitProposalRequiredPower();
      this.canVote = await this.alohaService.getUserCanVote(
        this.alohaService.address
      );
      this.userPower = await this.alohaService.getUserPower(
        this.alohaService.address
      );
      this.canSubmitByPower =
        this.userPower >= this.submitProposalRequiredPower;
      this.canSubmitByDelay = this.now > new Date(this.canVote);
      this.alohaService.loading = false;
    }
  }

  async submitOffChainProposal(): Promise<void> {
    if (!this.canSubmitByPower) {
      alert(
        'You need more than ' +
          this.submitProposalRequiredPower +
          ' power to submit a proposal'
      );
      return;
    }
    if (!this.canSubmitByDelay) {
      const date = new Date(this.canVote);
      alert("You can't submit a proposal until: " + date.toLocaleString());
      return;
    }

    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }

    this.alohaService.submitOffChainProposal(this.offChainDetails);
  }

  async submitOnChainProposal(): Promise<void> {
    if (!this.canSubmitByPower) {
      alert(
        'You need more than ' +
          this.submitProposalRequiredPower +
          ' power to submit a proposal'
      );
      return;
    }
    if (!this.canSubmitByDelay) {
      const date = new Date(this.canVote);
      alert("You can't submit a proposal until: " + date.toLocaleString());
      return;
    }

    const isAddress = /^0x[a-fA-F0-9]{40}$/g;
    const isValue = /^[0-9]*$/;
    const isData = /^0x[a-fA-F0-9]{1,}$/;

    if (!isAddress.exec(this.actionTo)) {
      alert('Action To must be an contract address.');
    }
    if (!isValue.exec(this.actionValue)) {
      alert('Action Value must be Wei number.');
    }
    if (!isData.exec(this.actionTo)) {
      alert('Action Data must be a bytecode.');
    }
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }

    this.alohaService.submitOnChainProposal(
      this.actionTo,
      this.actionValue,
      this.actionData,
      this.details
    );
  }
}
