<div class="slim-mainpanel proposal">
  <div class="container">
    <div class="row custom-padding justify-content-center">
      <div class="col-lg-12 farm-title pd-b-20">
        <img
          src="assets/img/cards/rank/vip.png"
          alt="img-mobile"
          class="hg-50"
        /><img
          src="assets/img/cards/rank/ultra-rare.png"
          alt="img-mobile"
          class="pd-l-20 hg-50"
        /><img
          src="assets/img/cards/rank/rare.png"
          alt="img-mobile"
          class="pd-l-20 hg-50"
        />
      </div>
      <div class="col-lg-12 farm-title title-vip txt-line-vip">
        <span>PROPOSALS</span>
      </div>
    </div>

    <div class="px-4 container-lg mx-auto pt-3">
      <div class="mb-3 d-flex">
        <div class="flex-auto">
          <div>Governance</div>
          <div class="d-flex flex-items-center flex-auto">
            <h2 class="mr-2 text-white">
              Proposals
              <span class="Counter ml-1">{{ proposals?.length }}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-3 pb-5">
      <div class="col-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="all-tab"
              data-toggle="tab"
              href="#all"
              role="tab"
              aria-controls="all"
              aria-selected="true"
              >All</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="active-all-tab"
              data-toggle="tab"
              href="#active-all"
              role="tab"
              aria-controls="active-all"
              aria-selected="false"
              >Active</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="closed-tab"
              data-toggle="tab"
              href="#closed"
              role="tab"
              aria-controls="closed"
              aria-selected="false"
              >Ended</a
            >
          </li>
          <li class="li-add d-flex" *ngIf="userPower > 0">
            <button
              class="btn btn-oblong btn-aloha btn-rare mr-2"
              tabindex="0"
              data-toggle="modal"
              data-target="#addOnChainProposal"
            >
              <i class="fas fa-plus"></i> ON-CHAIN
            </button>
            <button
              class="btn btn-oblong btn-aloha btn-rare"
              tabindex="0"
              data-toggle="modal"
              data-target="#addOffChainProposal"
            >
              <i class="fas fa-plus"></i> OFF-CHAIN
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="all"
            role="tabpanel"
            aria-labelledby="all-tab"
          >
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr *ngIf="proposals?.length == 0">
                    <th scope="row">
                      <div class="px-4 py-3 d-block text-gray">
                        <h2 class="d-inline-block mb-1 text-white">No data</h2>
                      </div>
                    </th>
                  </tr>
                  <tr *ngFor="let proposal of proposals">
                    <th scope="row">
                      <a
                        href="/view-proposal/{{ proposal.id }}"
                        class="px-4 py-3 d-block text-gray"
                      >
                        <div>
                          <span
                            class="bg-purple state d-inline-block mr-2 mb-2"
                            *ngIf="proposal.review == 0"
                            >Waiting review</span
                          >
                          <span
                            class="bg-teal state d-inline-block mr-2 mb-2"
                            *ngIf="
                              proposal.review == 1 &&
                              proposal.starting + votingDuration > now
                            "
                            >Active</span
                          >
                          <span
                            class="bg-danger state d-inline-block mr-2 mb-2"
                            *ngIf="
                              proposal.review == 1 &&
                              now > proposal.starting + votingDuration
                            "
                            >Ended</span
                          >
                          <h2 class="d-inline-block mb-1 text-white">
                            Proposal {{ proposal.id }}
                          </h2>
                        </div>
                        <div>
                          <span class="subtext">
                            By {{ proposal.proposer }}
                            <div *ngIf="proposal.starting > 0">
                              <strong class="mr-2">Start</strong
                              ><span>{{
                                proposal.starting | date: "dd/MM/yy HH:mm"
                              }}</span>
                              <strong class="mx-2">End</strong
                              ><span>{{
                                proposal.starting + votingDuration
                                  | date: "dd/MM/yy HH:mm"
                              }}</span>
                            </div>
                          </span>
                        </div>
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="active-all"
            role="tabpanel"
            aria-labelledby="active-all-tab"
          >
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr *ngIf="activeProposals?.length == 0">
                    <th scope="row">
                      <div class="px-4 py-3 d-block text-gray">
                        <h2 class="d-inline-block mb-1 text-white">No data</h2>
                      </div>
                    </th>
                  </tr>
                  <tr *ngFor="let proposal of activeProposals">
                    <th scope="row">
                      <a
                        href="/view-proposal/{{ proposal.id }}"
                        class="px-4 py-3 d-block text-gray"
                      >
                        <div>
                          <span class="bg-teal state d-inline-block mr-2 mb-2"
                            >Active</span
                          >
                          <h2 class="d-inline-block mb-1 text-white">
                            Proposal {{ proposal.id }}
                          </h2>
                        </div>
                        <div>
                          <span class="subtext">
                            By {{ proposal.proposer }}
                            <div *ngIf="proposal.starting > 0">
                              <strong class="mr-2">Start</strong
                              ><span>{{
                                proposal.starting | date: "dd/MM/yy HH:mm"
                              }}</span>
                              <strong class="mx-2">End</strong
                              ><span>{{
                                proposal.starting + votingDuration
                                  | date: "dd/MM/yy HH:mm"
                              }}</span>
                            </div>
                          </span>
                        </div>
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="closed"
            role="tabpanel"
            aria-labelledby="closed-tab"
          >
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr *ngIf="endedProposals?.length == 0">
                    <th scope="row">
                      <div class="px-4 py-3 d-block text-gray">
                        <h2 class="d-inline-block mb-1 text-white">No data</h2>
                      </div>
                    </th>
                  </tr>
                  <tr *ngFor="let proposal of endedProposals">
                    <th scope="row">
                      <a
                        href="/view-proposal/{{ proposal.id }}"
                        class="px-4 py-3 d-block text-gray"
                      >
                        <div>
                          <span class="bg-danger state d-inline-block mr-2 mb-2"
                            >Ended</span
                          >
                          <h2 class="d-inline-block mb-1 text-white">
                            Proposal {{ proposal.id }}
                          </h2>
                        </div>
                        <div>
                          <span class="subtext">
                            By {{ proposal.proposer }}
                            <div *ngIf="proposal.starting > 0">
                              <strong class="mr-2">Start</strong
                              ><span>{{
                                proposal.starting | date: "dd/MM/yy HH:mm"
                              }}</span>
                              <strong class="mx-2">End</strong
                              ><span>{{
                                proposal.starting + votingDuration
                                  | date: "dd/MM/yy HH:mm"
                              }}</span>
                            </div>
                          </span>
                        </div>
                      </a>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="addOnChainProposal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addOnChainProposalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Action To</label>
            <input
              type="text"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="actionTo"
              placeholder="0xb8ffdec2020a86cED63E00F863406597e0b1aC2d"
            />
            <small id="" class="form-text text-muted">Contract address.</small>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Action Value</label>
            <input
              type="text"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="actionValue"
              placeholder="1000000000000000000"
            />
            <small id="" class="form-text text-muted"
              >Action value to send to contract.</small
            >
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Action Data</label>
            <input
              type="text"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="actionData"
              placeholder="0xd82e3962000000000000000000000000b8ffdec2020a86ced63e00f863406597e0b1ac2d"
            />
            <small id="" class="form-text text-muted"
              >Action data to send to contract.</small
            >
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Details</label>
            <input
              type="text"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="details"
              placeholder="http://www.github.com/readme.txt"
            />
            <small id="" class="form-text text-muted"
              >Plain file URL that explains the proposal.</small
            >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-oblong btn-aloha btn-rare text-uppercase"
          (click)="submitOnChainProposal()"
        >
          Add Proposal
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addOffChainProposal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addOffChainProposalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Details</label>
            <input
              type="text"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="offChainDetails"
              placeholder="http://www.github.com/readme.txt"
            />
            <small id="" class="form-text text-muted"
              >Plain file URL that explains the proposal.</small
            >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-oblong btn-aloha btn-rare text-uppercase"
          (click)="submitOffChainProposal()"
        >
          Add Proposal
        </button>
      </div>
    </div>
  </div>
</div>
