<div class="slim-mainpanel view-proposal">
    <div class="container">
        <div class="row custom-padding justify-content-center">
            <div class="col-lg-12 farm-title pd-b-20"><img src="assets/img/cards/rank/vip.png" alt="img-mobile"
                    class="hg-50"><img src="assets/img/cards/rank/ultra-rare.png" alt="img-mobile"
                    class="pd-l-20 hg-50"><img src="assets/img/cards/rank/rare.png" alt="img-mobile"
                    class="pd-l-20 hg-50"></div>
            <div class="col-lg-12 farm-title title-vip txt-line-vip"><span>View Proposal</span></div>
        </div>

        <div class="px-4 container-lg mx-auto pt-3">
            <div class="mb-3 d-flex">
                <div class="flex-auto">
                    <div><a href="/"><i class="fas fa-long-arrow-alt-left"></i> Back</a></div>
                    <div class="d-flex flex-items-center flex-auto">
                        <h1 class="mr-2 text-white">Proposal {{ proposal?.id }}</h1>
                        <span class="bg-purple state text-normal2" *ngIf="proposal?.review == 0">Waiting review</span>
                        <span class="bg-teal state text-normal2" *ngIf="proposal?.review == 1 && proposal?.starting + votingDuration > now">Active</span>
                        <span class="bg-danger state text-normal2" *ngIf="proposal?.review == 1 && now > proposal?.starting + votingDuration">Ended</span>

                        <button *ngIf="isMod && proposal?.review == 0" (click)="reviewProposal(proposal?.id, 'OK')" class="btn btn-aloha" style="margin: 8px">Accept Proposal</button>

                        <button *ngIf="isMod && proposal?.review == 0" (click)="reviewProposal(proposal?.id, 'KO')" class="btn btn-aloha" style="margin: 8px">Reject Proposal</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="row py-5">
            <div class="col-12 col-md-7 col-lg-8 block-content">
                <div class="content">
                    <div class="markdown-body break-word mb-6" *ngIf="!isUrl">
                        {{ details }}
                    </div>
                    <div class="markdown-body break-word mb-6" *ngIf="isUrl">
                        <a [href]="details" target="_blank">{{ details }} </a>
                    </div>
                </div>
                <div class="row pt-3 pb-5">
                    <div class="col-12">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <p class="text-white mb-0">Votos <span class="bg-purple state d-inline-block mb-0 ml-2">{{ lastVotes?.length }}</span></p>
                            </li>
                            <li class="li-add  ul-vote" *ngIf="userPower > 0 && !voted">
                                <button class="btn btn-oblong btn-aloha btn-ok mr-2" tabindex="0" (click)="voteProposal(proposal.id, true)">VOTE <i class="far fa-thumbs-up ml-2"></i></button>
                                <button class="btn btn-oblong btn-aloha btn-ko" tabindex="0" (click)="voteProposal(proposal.id, false)">VOTE <i class="far fa-thumbs-down ml-2"></i></button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                                <div class="table-responsive">
                                    <table class="table votos">
                                        <tbody>
                                            <tr *ngFor="let vote of lastVotes">
                                                <th>{{ vote.user }}</th>
                                                <th>{{ vote.vote ? 'Yes' : 'No'  }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="active-all" role="tabpanel" aria-labelledby="active-all-tab">...
                            </div>
                            <div class="tab-pane fade" id="closed" role="tabpanel" aria-labelledby="closed-tab">...</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-4">
                <div class="card module block-features">
                    <div class="card-header text-white">
                        Featured
                    </div>
                    <ul class="list-group list-group-flush detalles">
                        <li class="list-group-item text-white">
                            <p class="mb-0">Proposer:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item">{{ proposal?.proposer }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">Start date:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item" *ngIf="proposal?.starting == 0">–</li>
                                <li class="list-inline-item" *ngIf="proposal?.starting > 0">{{ proposal?.starting | date: 'dd/MM/yy HH:mm' }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">End date:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item" *ngIf="proposal?.starting == 0">–</li>
                                <li class="list-inline-item" *ngIf="proposal?.starting > 0">{{ (proposal?.starting + votingDuration) | date: 'dd/MM/yy HH:mm' }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">"Yes" power:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item" *ngIf="proposal?.starting == 0">–</li>
                                <li class="list-inline-item" *ngIf="proposal?.starting > 0">{{ proposal?.yesVotes }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">"No" power:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item" *ngIf="proposal?.starting == 0">–</li>
                                <li class="list-inline-item" *ngIf="proposal?.starting > 0">{{ proposal?.noVotes }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">Date created:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item">{{ proposal?.created | date: 'dd/MM/yy HH:mm' }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">Action:</p>
                            <ul class="list-item list-unstyle" *ngIf="proposal?.action.to == 0">
                                <li class="list-inline-item">Off-chain proposal</li>
                            </ul>
                            <ul class="list-item list-unstyle" *ngIf="proposal?.action.to != 0">
                                <li class="list-inline-item">{{ proposal?.action.to }}</li>
                                <li class="list-inline-item">{{ proposal?.action.value }}</li>
                                <li class="list-inline-item">{{ proposal?.action.data }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white" *ngIf="proposal?.action.to != 0">
                            <p class="mb-0">Executed:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item">{{ proposal?.action.executed == false ? 'No' : 'Yes' }}</li>
                            </ul>
                        </li>
                        <li class="list-group-item text-white">
                            <p class="mb-0">Status:</p>
                            <ul class="list-item list-unstyle">
                                <li class="list-inline-item">
                                    <span class="bg-purple state text-normal2" *ngIf="proposal?.review == 0">Waiting review</span>
                                    <span class="bg-teal state text-normal2" *ngIf="proposal?.review == 1 && proposal?.starting + votingDuration > now">Active</span>
                                    <span class="bg-danger state text-normal2" *ngIf="proposal?.review == 1 && now > proposal?.starting + votingDuration">Ended</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="card module block-resultados">
                    <div class="card-header text-white">
                        Resultados
                    </div>

                    <ul class="list-unstyle">
                        <li>
                            <p class="mb-0">YES POWER: <span>{{ proposal?.yesVotes }}</span></p>
                            <div class="progress">
                                
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': ((proposal?.yesVotes + proposal?.yesVotes) / proposal?.yesVotes * 100) + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        <li>
                            <p class="mb-0">NO POWER: <span>{{ proposal?.noVotes }}</span></p>
                            <div class="progress">
                                
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': ((proposal?.noVotes + proposal?.noVotes) / proposal?.noVotes * 100) + '%' }" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>