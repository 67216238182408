import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlohaCardComponent } from './components/aloha-card/aloha-card.component';
import { CardComponent } from './components/card/card.component';
import { DaoComponent } from './components/dao/dao.component';
import { ProposalsComponent } from './components/proposals/proposals.component';
import { ViewProposalComponent } from './components/view-proposal/view-proposal.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NoCommaPipe,
    DaoComponent,
    ProposalsComponent,
    ViewProposalComponent,
    AlohaCardComponent,
    CardComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
