import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardComponent } from './components/card/card.component';
import { DaoComponent } from './components/dao/dao.component';
import { ProposalsComponent } from './components/proposals/proposals.component';
import { ViewProposalComponent } from './components/view-proposal/view-proposal.component';

const routes: Routes = [
  {
    path: 'card/:id',
    component: CardComponent,
  },
  {
    path: 'view-proposal/:id',
    component: ViewProposalComponent,
  },
  {
    path: '',
    component: ProposalsComponent,
  },
  {
    path: 'dao',
    component: DaoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
