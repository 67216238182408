import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlohaService } from './services/aloha.service';
import { WalletService } from './services/wallet.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('walletModal') walletModal: ElementRef;

  constructor(
    private readonly walletService: WalletService,
    public readonly alohaService: AlohaService
  ) {}

  address: string = null;
  network: string = null;

  ngOnInit(): void {
    this.walletService.init();
    this.loadData();
    window.ethereum.on('networkChanged', () => {
      location.reload();
    });
  }

  async loadData() {
    await this.alohaService.configNetwork();
    this.walletService.getAccount().then((account) => {
      this.address = account;
    });

    this.network = this.alohaService.currentNetwork;
  }

  async connectWithMetamask(): Promise<void> {
    this.address = await this.walletService.connectWithMetamask();
    this.walletModal.nativeElement.click();
  }

  async connectWithWalletConnect(): Promise<void> {
    this.address = await this.walletService.connectWithWalletConnect();
    this.walletModal.nativeElement.click();
  }

  async switchToMatic(): Promise<void> {
    window['ethereum'].request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          chainName: 'Matic',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
        },
      ],
    });
  }
}
