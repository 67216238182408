import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { environment } from '../../environments/environment';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root',
})
export class AlohaService {
  account;
  checkConnection;
  proposals;
  votingDuration;
  now;
  loading = false;

  provider;
  web3;
  address;
  currentChainId;
  rootChainToken;
  childChainToken;
  ERC20PredicateProxy;
  ERC721PredicateProxy;
  goerliChainId = 0x5;
  ethereumChainId = 0x1;
  mumbaiChainId = 0x13881;
  maticChainId = 0x89;
  connectedToRootChain = true;
  rootProvider;
  childProvider;
  rootChainNFT;
  childChainNFT;
  rootChainNftUtils;
  childChainNftUtils;
  currentNetworkNftUtils;
  currentNetworkToken;
  currentNetworkNFT;
  currentNetworkGovernance;
  rootChainGovernance;
  childChainGovernance;
  currentNetwork = 'Ethereum';
  otherNetwork = 'Matic';

  daoUtilsAddress = '0x17F429bF1f642b9c782977C36d76585acfa17EF8';

  allDeposits = [];
  allWithdrawals = [];

  constructor(private readonly wallet: WalletService) {}

  async configNetwork(): Promise<void> {
    this.provider = window['ethereum'];
    this.web3 = new Web3(this.provider);

    const accounts = await this.web3.eth.getAccounts();
    this.address = accounts[0];

    this.currentChainId = await this.web3.eth.getChainId();

    this.rootChainToken = '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe';
    this.childChainToken = '0x60AC2E84078Ce30CBC68e3d7b18bCF613271ce6B';
    this.ERC20PredicateProxy = '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf';

    this.rootChainNFT = '0x524833d8b9C2194Ead830Fd205E8fAEd9801E776';
    this.childChainNFT = '0x75e93aC621B38ae11E5c95A383a37Ce8d657b19E';
    this.ERC721PredicateProxy = '0xE6F45376f64e1F568BD1404C155e5fFD2F80F7AD';

    this.rootChainNftUtils = '0x11d1816da0c7111aa39145e3509ba1349fffa6b4';
    this.childChainNftUtils = '0xB4b40640fEb2374540b97eb90FdF0AE6dd42c6F5';

    this.rootChainGovernance = '0x3bbF2bEC541bDff23C4a6B3F16BdAB77488d72d2';
    this.childChainGovernance = '0x3bbF2bEC541bDff23C4a6B3F16BdAB77488d72d2';

    this.currentNetworkToken = this.rootChainToken;
    this.currentNetworkNFT = this.rootChainNFT;
    this.currentNetworkGovernance = this.rootChainGovernance;
    this.currentNetworkNftUtils = this.rootChainNftUtils;

    if (this.currentChainId === this.maticChainId) {
      this.currentNetworkToken = this.childChainToken;
      this.currentNetworkNFT = this.childChainNFT;
      this.currentNetworkGovernance = this.childChainGovernance;
      this.currentNetworkNftUtils = this.childChainNftUtils;
      this.connectedToRootChain = false;
      this.currentNetwork = 'Matic';
      this.otherNetwork = 'Ethereum';
    }

    this.rootProvider = new Web3.providers.HttpProvider(
      'https://mainnet.infura.io/v3/511f4d37e606408d87d9b32b600e722e'
    );
    this.childProvider = new Web3.providers.HttpProvider(
      'https://rpc-mainnet.maticvigil.com/v1/bb4fbe8428c3a3504cb5c96aca2b875a55c8d957'
    );
  }

  async getProposals(): Promise<any[]> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const proposalCount = await contract.methods.proposalCount().call();

    const proposals = [];

    for (let index = 0; index < proposalCount; index++) {
      const proposal = await contract.methods.proposals(index).call();

      if (proposal.created === '0' || proposal.review === '2') {
        continue;
      }

      proposals.push({
        id: index,
        action: {
          data: proposal.action.data,
          executed: proposal.action.executed,
          to: proposal.action.to,
          value: proposal.action.value,
        },
        details: proposal.details,
        review: proposal.review,
        yesVotes: proposal.yesVotes,
        noVotes: proposal.noVotes,
        proposer: proposal.proposer,
        starting: Number(proposal.starting) * 1000,
        created: Number(proposal.created) * 1000,
      });
    }

    return proposals.reverse();
  }

  async getProposal(id): Promise<any> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const proposal = await contract.methods.proposals(id).call();

    let response = {};

    if (proposal.created === '0' || proposal.review === '2') {
      return response;
    }

    response = {
      id,
      action: {
        data: proposal.action.data,
        executed: proposal.action.executed,
        to: proposal.action.to,
        value: proposal.action.value,
      },
      details: proposal.details,
      review: proposal.review,
      yesVotes: Number(proposal.yesVotes),
      noVotes: Number(proposal.noVotes),
      proposer: proposal.proposer,
      starting: Number(proposal.starting) * 1000,
      created: Number(proposal.created) * 1000,
    };

    return response;
  }

  async getVotes(proposalId: string): Promise<any[]> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const participants = await this.getParticipants();

    const votes = [];
    for (const participantAddress of participants) {
      const vote = await contract.methods.getUserVoteByProposal(proposalId, participantAddress).call();
      if (vote !== '0') {
        votes.push({
          user: participantAddress,
          vote: (vote === '1' ? true : false )
        });
      }
    }

    return votes;
  }

  async getVoteEvents(proposalId: string): Promise<any[]> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const response = [];
    const lastBlock = await this.web3.eth.getBlockNumber();

    for (let block = environment.initialBlock; block < Number(lastBlock); block += 1000) {
      const fromBlock = (block + 1);
      let toBlock = (block + 1000);

      if (toBlock > Number(lastBlock)) {
        toBlock = Number(lastBlock) - 1;
        block = Number(lastBlock);
      }

      const lastVotes = await contract.getPastEvents('VotedProposal', {
        filter: {created: proposalId.toString()},
        fromBlock,
        toBlock,
      }, (error, events) => {
        return events;
      });

      for (const vote of lastVotes) {
        response.push({
          user: vote.returnValues.user,
          vote: vote.returnValues.vote,
          created: Number(vote.returnValues.created) * 1000,
        });
      }

    }

    return response.reverse();
  }

  async getUserPower(address: string): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const response = await contract.methods.stakeMap(address).call();

    return Number(response);
  }

  async ownersInDao(): Promise<[]> {
    const abi = require('../../assets/abis/DAOUtils.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.daoUtilsAddress
    );

    return await contract.methods.tokensOwners(this.childChainGovernance, this.childChainNFT, this.childChainGovernance).call();
  }

  async daoRarities(): Promise<[]> {
    const abi = require('../../assets/abis/DAOUtils.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.daoUtilsAddress
    );

    return await contract.methods.tokensRarities(this.childChainNFT, this.childChainGovernance).call();
  }

  async tokensOfOwner(address: string): Promise<[]> {
    const abi = require('../../assets/abis/NFTUtils.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.childChainNftUtils
    );

    const response = await contract.methods.tokensOfOwner(this.childChainNFT, address).call();

    return response;
  }

  async getUserCanVote(address: string): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const response = await contract.methods.users(address).call();

    return response.canVote * 1000;
  }

  async getUserCanWithdraw(address: string): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const response = await contract.methods.users(address).call();

    return response.canWithdraw * 1000;
  }

  async getTotalPower(): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    return Number(await contract.methods.totalPower().call());
  }

  async getDeposits(address: string = null): Promise<any[]> {
    if (this.allDeposits.length === 0) {
      await this.getAllDeposits();
    }

    const depositedByAddress = [];

    for (const deposit of this.allDeposits) {
      if (address === null || deposit.user.toUpperCase() === address.toUpperCase()) {
        depositedByAddress.push(deposit);
      }
    }

    return depositedByAddress;
  }

  async getSubmitProposalRequiredPower(): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    return Number(await contract.methods.submitProposalRequiredPower().call());
  }

  async getWithdrawDelay(): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    return Number(await contract.methods.withdrawalDelay().call()) * 1000;
  }

   async getVotingDelay(): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    return Number(await contract.methods.votingDelay().call()) * 1000;
  }

  async getVotingDuration(): Promise<number> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    return Number(await contract.methods.votingDuration().call()) * 1000;
  }

  async getParticipants(): Promise<Set<any>> {
    if (this.allDeposits.length === 0) {
      await this.getAllDeposits();
    }

    return new Set((this.allDeposits.map(item => item.user)));
  }

  async getAllDeposits(): Promise<any[]> {
    const response = [];
    const daoTokens = await this.tokensOfOwner(this.currentNetworkGovernance);
    const owners = await this.ownersInDao();
    const rarities = await this.daoRarities();

    for (let i = 0; i < daoTokens.length; i++) {
      response[i] = {
        user: owners[i],
        tokenId: daoTokens[i],
        power: this.tokenPower(rarities[i]),
      };
    }

    this.allDeposits = response;

    return this.allDeposits;
  }

  async withdraw(tokenId: string): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    await contract.methods.withdraw(tokenId).send({ from: this.address });
  }

  async deposit(tokenId: string): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    await contract.methods.deposit(tokenId).send({ from: this.address });
  }

  async voteProposal(proposalId: number, value: boolean): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    await contract.methods.voteProposal(proposalId, (value ? '1' : '2')).send({ from: this.address });
  }

  async reviewProposal(proposalId: number, status: 'OK' | 'KO'): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    await contract.methods.reviewProposal(proposalId, (status === 'OK' ? '1' : '2')).send({ from: this.address });
  }

  async isModerator(): Promise<boolean> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );
    return await contract.methods
      .proposalModerator()
      .call() === this.address;
  }

  async isApproved(): Promise<boolean> {
    const abi = require('../../assets/abis/AlohaNFT.json');
    const contract = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(abi, this.currentNetworkNFT);

    return await contract.methods
      .isApprovedForAll(this.address, this.currentNetworkGovernance)
      .call();
  }

  async approve(): Promise<void> {
    const abi = require('../../assets/abis/AlohaNFT.json');
    const contract = new this.web3.eth.Contract(abi, this.currentNetworkNFT);

    await contract.methods
      .setApprovalForAll(this.currentNetworkGovernance, true)
      .send({ from: this.address });
  }

  async submitOffChainProposal(details: string): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.web3.eth.Contract)(
      abi,
      this.currentNetworkGovernance
    );

    const proposalId = await contract.methods.proposalCount().call();

    await contract.methods.submitOffChainProposal(proposalId, details).send({ from: this.address });
  }

  async pendingRewards(): Promise<string> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.wallet.getMaticInfuraWeb3().eth.Contract)(
      abi,
      this.currentNetworkGovernance
    );
    return await contract.methods.claim().call({ from: this.address });
  }

  async claimDaoRewards(): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new (this.web3.eth.Contract)(
      abi,
      this.currentNetworkGovernance
    );
    await contract.methods.claim().send({ from: this.address });
  }

  async submitOnChainProposal(
    actionTo: string,
    actionValue: string,
    actionData: string,
    details: string
  ): Promise<void> {
    const abi = require('../../assets/abis/AlohaGovernance.json');
    const contract = new this.web3.eth.Contract(
      abi,
      this.currentNetworkGovernance
    );

    const proposalId = await contract.methods.proposalCount().call();

    await contract.methods.submitOnChainProposal(
      proposalId,
      actionTo,
      actionValue,
      actionData,
      details
    ).send({ from: this.address });
  }

  getSerieByImage(image: string): string {
    return parseInt(image, undefined) <= 6 ? '1' : '2';
  }

  async getNftById(
    id: number
  ): Promise<{
    owner: string;
    image: string;
    background: string;
    rarity: string;
  }> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );
    return {
      owner: await alohaNFT.methods.ownerOf(id).call(),
      image: await alohaNFT.methods.tokenImage(id).call(),
      background: await alohaNFT.methods.tokenBackground(id).call(),
      rarity: await alohaNFT.methods.tokenRarity(id).call(),
    };
  }

  async transfer(address: string, tokenId: string): Promise<void> {
    const account = this.account;
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    await alohaNFT.methods
      .transferFrom(account, address, tokenId)
      .send({ from: account });
  }

  async getUserNFT(): Promise<object> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getMaticInfuraWeb3()).eth.Contract(
      alohaNFTAbi,
      this.childChainNFT
    );

    const myNFTs = [];
    const tokens = await this.tokensOfOwner(this.address);

    for (const token of tokens) {
      myNFTs.push({
        id: token,
        image: await alohaNFT.methods.tokenImage(token).call(),
        background: await alohaNFT.methods.tokenBackground(token).call(),
        rarity: await alohaNFT.methods.tokenRarity(token).call(),
        uri: await alohaNFT.methods.tokenRarity(token).call(),
      });
    }

    return myNFTs;
  }

  tokenPower(rarity: number): number {
    const powerByRarity = [1, 5, 50];
    return powerByRarity[rarity - 1];
  }
}
