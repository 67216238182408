<div class="slim-mainpanel">
  <div *ngIf="!alohaService?.address">
    <div class="container" style="padding-bottom: 200px">
      <div class="row custom-padding justify-content-center">
        <div class="col-lg-12 farm-title pd-b-20">
          <img
            src="assets/img/cards/rank/vip.png"
            alt="img-mobile"
            class="hg-50"
          /><img
            src="assets/img/cards/rank/ultra-rare.png"
            alt="img-mobile"
            class="pd-l-20 hg-50"
          /><img
            src="assets/img/cards/rank/rare.png"
            alt="img-mobile"
            class="pd-l-20 hg-50"
          />
        </div>
        <div class="col-lg-12 farm-title title-vip txt-line-vip">
          <span>PLEASE, CONNECT WITH METAMASK</span>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="alohaService?.address">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>My wallet</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row py-5">
      <div class="col-12 col-md-7 col-lg-8 block-content">
        <div class="row pb-5">
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="all-tab"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                  aria-controls="all"
                  aria-selected="true"
                  >Actives</a
                >
              </li>
              <li class="li-add">
                <button
                  class="btn btn-oblong btn-aloha btn-block btn-rare"
                  tabindex="0"
                  data-toggle="modal"
                  data-target="#addToken"
                  *ngIf="tokenList?.length > 0"
                >
                  DEPOSIT TOKEN
                </button>
                <a
                  class="btn btn-oblong btn-aloha btn-block btn-rare text-white"
                  href="https://nft.alohadefi.io"
                  target="_blank"
                  *ngIf="tokenList?.length == 0"
                  >GET TOKENS</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr *ngIf="userDeposits?.length == 0">
                        <th>No data</th>
                      </tr>
                      <tr *ngFor="let deposit of userDeposits">
                        <th>Token {{ deposit.tokenId }}</th>
                        <th>Power {{ deposit.power }}</th>
                        <th></th>
                        <th>
                          <a
                            (click)="withdraw(deposit.tokenId)"
                            href="javascript:;"
                            title="withdraw"
                            ><i class="fas fa-sign-out-alt"></i
                          ></a>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-4">
        <div class="card module block-features">
          <div class="card-header text-white">My Wallet</div>
          <ul class="list-group list-group-flush detalles">
            <li class="list-group-item text-white">
              <p class="mb-0">Total Power:</p>
              <ul class="list-item list-unstyle">
                <li class="list-inline-item">{{ userPower }}</li>
              </ul>
            </li>
            <li class="list-group-item text-white">
              <p class="mb-0">Percentage:</p>
              <ul class="list-item list-unstyle">
                <li class="list-inline-item" *ngIf="userPower == 0">0%</li>
                <li class="list-inline-item" *ngIf="userPower > 0">
                  {{ (userPower / totalPower) * 100 | number }}%
                </li>
              </ul>
            </li>
            <li class="list-group-item text-white">
              <p class="mb-0">Your Rewards:</p>
              <ul class="list-item list-unstyle">
                <li class="list-inline-item">{{ pendingRewards }} ALOHA</li>
              </ul>
            </li>
            <li class="list-group-item text-white" *ngIf="pendingRewards != 0">
              <p class="mb-0">Claim Rewards:</p>
              <ul class="list-item list-unstyle">
                <li class="list-inline-item">
                  <button
                    type="button"
                    class="btn btn-oblong btn-aloha btn-rare text-uppercase"
                    (click)="claim()"
                  >
                    Claim
                  </button>
                </li>
              </ul>
            </li>
            <li class="list-group-item text-white">
              <p class="mb-0">Deposited:</p>
              <ul
                class="list-item ul-participantes list-unstyled"
                *ngIf="userDeposits?.length == 0"
              >
                <li class="list-inline-item">None</li>
              </ul>
              <ul
                class="list-item ul-participantes list-unstyled"
                *ngIf="userDeposits?.length != 0"
              >
                <li
                  class="list-inline-item"
                  *ngFor="let deposit of userDeposits"
                >
                  <a href="https://nft.alohadefi.io/card/{{ deposit.tokenId }}">
                    Token {{ deposit.tokenId }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="card module block-resultados">
          <div class="card-header text-white">Global</div>
          <ul class="list-group list-group-flush detalles">
            <li class="list-group-item text-white">
              <p class="mb-0">Total Power:</p>
              <ul class="list-item list-unstyle">
                <li class="list-inline-item">{{ totalPower }}</li>
              </ul>
            </li>
            <li class="list-group-item text-white li-participantes">
              <p class="mb-0">Participants:</p>
              <ul
                class="mt-2 ul-participantes"
                *ngIf="participants?.length == 0"
              >
                <li>None</li>
              </ul>
              <ul class="mt-2 ul-participantes list-unstyled">
                <li class="mb-1" *ngFor="let depositAddress of participants">
                  <a
                    href="https://explorer-mainnet.maticvigil.com/address/{{
                      depositAddress
                    }}"
                    target="_blank"
                    >{{ depositAddress | slice: 0:12 }}...{{
                      depositAddress | slice: 30:64
                    }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="addToken"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addTokenTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="title mb-0">Select token</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0 d-flex">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <ul class="list-unstyled list-inline">
                <li *ngFor="let token of tokenList" class="list-inline-item">
                  <button
                    type="button"
                    class="
                      btn btn-oblong btn-aloha btn-rare
                      text-uppercase
                      mr-2
                      mb-2
                    "
                    (click)="deposit(token.id)"
                  >
                    <i class="fas fa-plus"></i> token {{ token.id }} (Power:
                    {{ tokenPower(token.rarity) }})
                  </button>
                </li>
              </ul>
            </div>
            <div class="col-lg-12 pd-t-20">
              Time to wait before deposit power counts: {{ beforeDeposit }} days
            </div>
            <div class="col-lg-12">
              Time to wait before withdraw deposits: {{ withdrawDelay }} days
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
