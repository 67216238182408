import { Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-dao',
  templateUrl: './dao.component.html',
  styleUrls: ['./dao.component.scss'],
})
export class DaoComponent implements OnInit {
  account;
  userPower;
  totalPower;
  userDepositedNFT;
  userDeposits;
  userWithdrawals;
  globalDepositedNFT;
  now;
  checkConnection;
  tokenList;
  participants;
  withdrawDelay;
  beforeDeposit;
  canWithdraw;
  canWithdrawByDelay = false;
  pendingRewards = '...';

  constructor(
    public readonly alohaService: AlohaService,
    private readonly wallet: WalletService
  ) {}

  async ngOnInit(): Promise<void> {
    this.now = Math.floor(Date.now());
    await this.alohaService.configNetwork();

    this.loadPoolData();

    if (this.alohaService.address) {
      this.alohaService.loading = true;

      this.userPower = await this.alohaService.getUserPower(
        this.alohaService.address
      );
      this.totalPower = await this.alohaService.getTotalPower();
      this.userDeposits = await this.alohaService.getDeposits(
        this.alohaService.address
      );
      this.participants = await this.alohaService.getParticipants();
      this.tokenList = await this.alohaService.getUserNFT();
      this.canWithdraw = await this.alohaService.getUserCanWithdraw(
        this.alohaService.address
      );

      this.canWithdrawByDelay = this.now > new Date(this.canWithdraw);
      this.withdrawDelay = Math.floor(
        (await this.alohaService.getWithdrawDelay()) / (3600 * 24) / 1000
      );
      this.beforeDeposit = Math.floor(
        (await this.alohaService.getVotingDelay()) / (3600 * 24) / 1000
      );

      this.alohaService.loading = false;
    }

    if (!this.alohaService.address) {
      this.checkConnection = setInterval(async () => {
        await this.alohaService.configNetwork();
        if (this.alohaService.address) {
          location.reload();
          clearInterval(this.checkConnection);
        }
      }, 1000);
    }
  }

  async loadPoolData(): Promise<void> {
    try {
      this.pendingRewards = parseFloat(
        await this.wallet
          .getWeb3()
          .utils.fromWei(await this.alohaService.pendingRewards(), 'ether')
      ).toFixed(4);
    } catch (e) {
      console.error(e);
    }
  }

  async claim(): Promise<void> {
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }
    this.alohaService.loading = true;
    try {
      await this.alohaService.claimDaoRewards();
      this.pendingRewards = '0';
    } catch (error) {
      console.error(error);
    }
    this.alohaService.loading = false;
  }

  async ngOnDestroy(): Promise<void> {
    clearInterval(this.checkConnection);
  }

  async withdraw(tokenId: string): Promise<void> {
    if (!this.canWithdrawByDelay) {
      const date = new Date(this.canWithdraw);
      alert("You can't withdraw tokens until: " + date.toLocaleString());
      return;
    }
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }
    await this.alohaService.withdraw(tokenId);
  }

  async deposit(tokenId: string): Promise<void> {
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }
    this.alohaService.loading = true;

    if (!(await this.alohaService.isApproved())) {
      alert(
        'You must first approve token transfer, please approve the next two transactions.'
      );
      await this.alohaService.approve();
    }

    try {
      await this.alohaService.deposit(tokenId);
    } catch (error) {}

    this.alohaService.loading = false;

    location.reload();
  }

  tokenPower(rarity: number): number {
    const powerByRarity = [1, 5, 50];
    return powerByRarity[rarity - 1];
  }
}
