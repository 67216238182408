<div class="loading" *ngIf="alohaService?.loading"></div>

<!-- START HEADER -->
<div class="slim-navbar">
  <div class="container">
    <ul class="nav center">
      <li class="nav-item">
        <a class="none" [routerLink]="'/'">
          <img
            src="assets/img/logo-dao.svg"
            class="wd-custom pointer"
            alt="shaka_logo"
          />
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link just-right" [routerLink]="'/'">
          <img
            src="assets/img/stats.png"
            class="icon wth-30"
            alt="stake_logo"
          />
          <span>Proposals</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/dao'">
          <img src="assets/img/stake.png" class="icon wth-30" alt="dao_logo" />
          <span>DAO</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="network == 'Ethereum'">
        <a class="nav-link" href="javascript:;" (click)="switchToMatic()">
          <span class="btn-wallet"> SWITCH TO MATIC </span>
        </a>
      </li>

      <li class="nav-item" *ngIf="!address">
        <a class="nav-link">
          <span
            class="btn-wallet"
            data-toggle="modal"
            data-target="#walletModal"
            >Connect wallet</span
          >
        </a>
      </li>
      <li class="nav-item" *ngIf="address">
        <a class="nav-link" href="javascript:;">
          <span class="btn-wallet">
            {{ address | slice: 0:4 }}..{{ address | slice: 39:64 }} [{{
              network == "Ethereum" ? "ETH" : "MATIC"
            }}]
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- END HEADER -->

<router-outlet></router-outlet>

<div class="footer">
  <div class="img-footer txt-center">
    <img
      class="main-coorp pd-b-40"
      src="assets/img/footer-main.png"
      alt="footer-main"
    />
  </div>
  <img class="img-footer" src="assets/img/footer.png" alt="footer" />
</div>

<div #walletModal id="walletModal" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content tx-size-sm">
      <div class="modal-body tx-center pd-y-20 pd-x-20">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="container">
          <div class="row pd-t-20 pd-b-20">
            <div class="col-lg-6">
              <div class="pointer" (click)="connectWithMetamask()">
                <img
                  class="logo-wallet"
                  src="assets/img/metamask.svg"
                  alt="footer-main"
                />
                <p class="wallet-txt">
                  Connect with <br />
                  Metamask
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="pointer" (click)="connectWithWalletConnect()">
                <img
                  class="logo-wallet"
                  src="assets/img/wallet-connect.svg"
                  alt="footer-main"
                />
                <p class="wallet-txt">
                  Connect with <br />
                  Wallet Connect
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
