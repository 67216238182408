import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-view-proposal',
  templateUrl: './view-proposal.component.html',
  styleUrls: ['./view-proposal.component.scss'],
})
export class ViewProposalComponent implements OnInit {
  proposal;
  details;
  votingDuration;
  now;
  voted;
  lastVotes;
  userPower;
  canVote;
  checkConnection;
  isUrl = false;
  isMod = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly httpClient: HttpClient,
    private readonly alohaService: AlohaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.now = Math.floor(Date.now());

    await this.alohaService.configNetwork();

    const id = this.route.snapshot.paramMap.get('id');
    this.proposal = await this.alohaService.getProposal(id);
    this.lastVotes = await this.alohaService.getVotes(id);
    this.canVote = await this.alohaService.getUserCanVote(this.alohaService.address);
    this.votingDuration = await this.alohaService.getVotingDuration();
    this.voted = (this.lastVotes.some(e => e.user === this.alohaService.address)) ? true : false;
    this.details = this.proposal.details;

    // Load proposal details if is URL
    try {
      const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      if (this.proposal.details.match(regex)) {
        this.isUrl = true;
        this.details = await this.httpClient
          .get(this.proposal.details, { responseType: 'text' })
          .toPromise();
      }
    } catch (e) { }

    if (this.alohaService.address) {
      this.userPower = await this.alohaService.getUserPower(
        this.alohaService.address
      );

      this.alohaService.isModerator().then(mod => this.isMod = mod);
    }

    if (!this.alohaService.address) {
      this.checkConnection = setInterval(async () => {
        await this.alohaService.configNetwork();
        if (this.alohaService.address) {
          location.reload();
          clearInterval(this.checkConnection);
        }
      }, 1000);
    }
  }

  async reviewProposal(proposalId: number, status: 'OK' | 'KO'): Promise<void> {
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }
    this.alohaService.loading = true;
    this.alohaService.reviewProposal(proposalId, status).finally(() => {
      this.alohaService.loading = false;
    });
  }

  async voteProposal(proposalId: number, value: boolean): Promise<void> {
    if (this.alohaService.currentNetwork !== 'Matic') {
      alert('Switch to Matic first');
      return;
    }

    const canVoteDate = new Date(this.canVote);
    if (this.canVote > this.now) {
      alert("You can't vote until: " + canVoteDate.toLocaleString());
      return;
    }

    this.alohaService.loading = true;
    this.alohaService.voteProposal(proposalId, value).finally(() => {
      this.alohaService.loading = false;
    });
  }
}
