import { Component, Input, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-aloha-card',
  templateUrl: './aloha-card.component.html',
  styleUrls: ['./aloha-card.component.scss'],
})
export class AlohaCardComponent implements OnInit {
  @Input() id: number;
  @Input() image: number;
  @Input() rarity: number;
  @Input() background: number;

  ribbon = false;
  imageSource = '';
  frameSource = '';
  imageType = 'static';

  constructor(private aloha: AlohaService) {}

  async ngOnInit() {
    await this.aloha.configNetwork();

    if (this.rarity < 1 || this.rarity > 3) {
      throw new Error('Invalid rarity: ' + this.rarity);
    }

    if (parseInt(this.image + '', undefined) === 13) {
      this.imageType = 'animated';
      this.imageSource = '/assets/img/cards/images/' + this.image + '.ogg';
      return;
    }

    this.imageSource = '/assets/img/cards/images/' + this.image + '.png';
    this.frameSource =
      '/assets/img/cards/frames/' +
      this.rarity +
      '_' +
      this.background +
      '_' +
      this.aloha.getSerieByImage(this.image + '') +
      '.png';

    // this.frameSource = '/assets/img/cards/frames/1_2_2.png';
  }
}
